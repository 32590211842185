import React, { Component } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/shared/seo";
import styled from "styled-components";
import Grid from "../styles/Grid";
import { fontFG30m, fontFG40m } from "../styles/fonts";
import breakpoints from "../styles/breakpoints";
import { graphql } from "gatsby";
import { getLocaleFromUrl } from "../utilities/i18n";
import { businessSignupLinks } from "../config";

const Title = styled.h1`
  ${fontFG40m};
`;

const Message = styled.div`
  ${fontFG30m};
`;

const Brief = styled.div``;

const TextContainer = styled.div`
  ${Title} {
    margin-bottom: 60px;
  }

  ${Message} {
    margin-bottom: 24px;
  }
`;

const Image = styled.div``;

const Container = styled(Grid)`
  padding: 60px 0;

  ${Title} {
    text-align: center;
    margin-bottom: 40px;
  }

  ${TextContainer} {
    margin-bottom: 60px;
  }

  ${Image} {
    grid-column: 2 / span 4;
    padding: 0 30px;
  }

  @media (min-width: ${breakpoints.lg}) {
    align-items: center;

    ${Title} {
      margin-bottom: 60px;
    }

    ${TextContainer} {
      grid-column: 4 / span 3;
      margin-bottom: 0;
    }

    ${Image} {
      grid-column: 8 / span 4;
      padding: 0;
    }
  }
`;

const checkToReroute = (url, currLocale) => {
  const reRouteToClientDashboard = url.includes("business/signup");
  if (reRouteToClientDashboard) {
    window.location.replace(businessSignupLinks[currLocale.key]);
  }
};

class NotFoundPage extends Component {
  // componentDidMount() {
  //   window.analytics.page("404 Not Found");
  // }

  render() {
    const {
      location,
      pageContext: { locale },
      data,
    } = this.props;

    const url = location.pathname;
    let currLocale = getLocaleFromUrl(url);
    checkToReroute(url, currLocale);
    const pageData = data.allPrismic404Page.edges.filter(
      (edge) => edge.node.lang === currLocale.key
    )[0].node.data;

    const imageData = getImage(pageData.image);

    return (
      <Layout location={location} locale={locale}>
        <Seo title={`${pageData.title}| Workmate`} />
        <Container>
          <Title>{pageData.title}</Title>
          <TextContainer>
            <Message>{pageData.message}</Message>
            <Brief>{pageData.brief}</Brief>
          </TextContainer>
          {imageData && (
            <Image>
              <GatsbyImage image={imageData} alt={pageData.image.alt} />
            </Image>
          )}
        </Container>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const query = graphql`
  query NotFoundQuery($localeKey: String) {
    allPrismic404Page {
      edges {
        node {
          lang
          data {
            title
            message
            brief
            image {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
    prismic404Page(lang: { eq: $localeKey }) {
      data {
        title
        message
        brief
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
`;
